
import Vue from 'vue';
import intl from '@/utils/intl';

interface ItemPerPage {
  text?: string | number;
  value?: string | number;
  divider?: boolean;
}

export default Vue.extend({
  name: 'Pagination',
  props: {
    showAllPages: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: [Number, String],
      default: 10
    },
    page: {
      type: [Number, String],
      default: 1
    },
    pageCount: {
      type: [Number, String],
      default: 0
    },
    itemsTotal: {
      type: [Number, String],
      default: 0
    },
    total: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    const allItemsPerPage: Array<ItemPerPage> = [
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '30', value: 30 },
      { text: '60', value: 60 },
      { text: '100', value: 100 },
      { text: '150', value: 150 }
    ];
    if (this.showAllPages) {
      allItemsPerPage.push({ divider: true });
      allItemsPerPage.push({ text: 'All', value: -1 });
    }

    return {
      modelItemsPerPage: Number(this.itemsPerPage),
      modelPage: Number(this.page),
      modelPageCount: Number(this.pageCount),
      allItemsPerPage: allItemsPerPage
    }
  },
  computed: {
    itemsPerPageLabel(): string {
      return intl.t('dataTable.itemsPerPageText');
    }
  },
  watch: {
    page(val): void {
      this.modelPage = Number(val);
    },
    pageCount(val): void {
      this.modelPageCount = Number(val);
    },
    modelPage(val: number): void {
      if (val) {
        this.$emit('onPageChange', val);
      }
    },
    modelItemsPerPage(val: number): void {
      if (val) {
        this.$emit('onItemsPerPageChange', val);
      }
    }
  }
});
