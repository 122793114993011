import store from '@/store';
import { each } from '@/utils/helpers';

type UserSetting = Record<string, string | boolean>;

export function userSettings(): UserSetting
{
  const result: UserSetting = {};
  each(store.getters.user.settings || {}, (item: Record<string, string>) => {
    result[item['setting_name']] = item['setting_value'];
  });

  return result;
}

export function getUserSettingByKey(key: string): unknown
{
  const results = userSettings();

  return results && key in results ? results[key] : null;
}
