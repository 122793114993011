
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
// import validators from '@/utils/validators';
import Http, { HttpError } from '@/utils/Http';

export default Vue.extend({
  name: 'InvoicesExportModal',
  // mixins: [validators],
  props: {
    small: Boolean,
    dark: Boolean,
    color: String,
    cssClass: String,
    title: {
      type: String,
      required: true
    },
    exportUrl: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    headers: Array
  },
  data() {
    return {
      modal: false,
      form: false,
      submitLoading: false,
      // Data
      name: '',
      pageNumber: -1,
      dateStartMenu: false,
      dateStart: null as string | null,
      dateStopMenu: false,
      dateStop: null as string | null
    }
  },
  computed: {
    firstDayOfWeek(): number {
      return intl.getFirstDayOfWeek();
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    okButton(): string {
      return intl.t('ok');
    }
  },
  watch: {
    async modal(status): Promise<void> {
      if (status) {
        const d = new Date();
        this.name = `export-${this.title.replace(/\s/g, '-')}-${d.getFullYear()}-${(d.getMonth() + 1)}-${d.getDate()}`.toLocaleLowerCase();
        this.currentMonth();
      }
    },
    pageNumber(value: number): void {
      if (value === -1) {
        this.currentMonth();
      } else {
        this.dateStart = null;
        this.dateStop = null;
      }
    }
  },
  methods: {
    setLabelName(name: string, ...params: Array<string | number>): string {
      return intl.t(`views.invoices.export.${name}Label`, ...params);
    },
    formatDate(val: string | null): string {
      return val ? intl.formatDate(val) : '';
    },
    currentMonth(): void {
      const d = new Date();
      this.dateStart = intl.formatDbDate(new Date(d.getFullYear(), d.getMonth(), 1));
      this.dateStop = intl.formatDbDate(new Date(d.getFullYear(), d.getMonth() + 1, 0));
    },
    close(): void {
      this.pageNumber = -1;
      this.dateStart = null;
      this.dateStop = null;
      this.modal = false;
    },
    async submit(): Promise<void> {
      if ((this.$refs.form as VForm).validate()) {
        this.submitLoading = true;
        const data = {
          name: this.name,
          extension: 'xlsx',
          date_start: this.dateStart,
          date_stop: this.dateStop,
          page: this.pageNumber,
          items_per_page: this.itemsPerPage,
          headers: this.headers
        };
        try {
          const response = await (new Http()).post(this.exportUrl, data, {
            responseType: 'blob'
          });
          if (response.data.size) {
            let blob = new Blob([response.data], { type: response.data.type });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.name + '.' + data.extension;
            link.click();
          }
        } catch (error) {
          const err = (error as HttpError).response?.data;
          const blob = new Blob([err], { type: err.type });
          const responseError = await blob.text();
          const errorResponse = JSON.parse(responseError);
          this.$root.$children[0].$children[0].setFlash('error', errorResponse.error.message);
        }
        this.submitLoading = false;
      }
    }
  }
});
