
import Vue from 'vue';
import { VForm } from 'vuetify/types/custom';
import intl from '@/utils/intl';
import validators from '@/utils/validators';
import Http, { HttpError } from '@/utils/Http';

export default Vue.extend({
  name: 'ImportFileModal',
  mixins: [validators],
  props: {
    small: Boolean,
    dark: Boolean,
    color: String,
    cssClass: String,
    title: String,
    type: String,
  },
  data() {
    return {
      modal: false,
      submitLoading: false,
      // Data
      file: null as string | Blob | null,
      fileErrors: []
    }
  },
  computed: {
    templateFileButton(): string {
      return intl.t('views.invoices.import.templateFileButton');
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    okButton(): string {
      return intl.t('ok');
    }
  },
  methods: {
    setLabelName(name: string, ...params: Array<string | number>): string {
      return intl.t(`views.invoices.import.${name}Label`, ...params);
    },
    close(): void {
      (this.$refs.form as VForm).reset();
      this.modal = false;
      this.fileErrors = [];
    },
    async submit(): Promise<void> {
      if ((this.$refs.form as VForm).validate()) {
        this.submitLoading = true;
        this.fileErrors = [];
        let formData = new FormData();
        if (this.file !== null) formData.append('file', this.file);
        try {
          const response = await (new Http()).post(`/v1/invoices/${this.type}/import`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.data) {
            this.$root.$children[0].$children[0].setFlash('success', intl.t('views.invoices.import.importedSuccessMessage'));
            this.$emit('success', response.data);
            this.close();
          }
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.$root.$children[0].$children[0].setFlash('error', err.message);
          if ('file' in err) {
            this.fileErrors = err.file;
          }
        }
        this.submitLoading = false;
      }
    }
  }
});
