
import Vue from 'vue';
import { Header } from '@/types/DataTable';
import { empty } from '@/utils/helpers';
import Http, { HttpError } from '@/utils/Http';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'InvoicesLogsModal',
  props: {
    small: Boolean,
    dark: Boolean,
    color: String,
    cssClass: String,
    title: {
      type: String,
      required: true
    },
    id: [Number, String],
    type: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      headers: [] as Array<Header>,
      items: []
    }
  },
  computed: {
    closeButton(): string {
      return intl.t('buttons.close');
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value: boolean): void {
        this.modal = value;
      }
    },
    async modal(value: boolean): Promise<void> {
      this.headers = [];
      if (value) {
        this.headers = [
          {
            text: 'ID',
            value: 'id_log',
            hidden: true
          }, {
            text: this.setLabelName('idUpload'),
            value: 'id_upload',
            class: 'text-no-wrap',
            cellClass: 'text-no-wrap'
          }, {
            text: this.setLabelName('idDownload'),
            value: 'id_download',
            class: 'text-no-wrap',
            cellClass: 'text-no-wrap'
          }, {
            text: this.setLabelName('status'),
            value: 'status',
            class: 'text-no-wrap',
            cellClass: 'text-no-wrap'
          }, {
            text: this.setLabelName('lastUpdate'),
            value: 'last_update',
            class: 'text-no-wrap',
            cellClass: 'text-no-wrap'
          }, {
            text: this.setLabelName('errorMessage'),
            value: 'error_message',
            hidden: true
          }, {
            text: this.setLabelName('anafStatus'),
            value: 'anaf_ap_istatus',
            class: 'text-no-wrap',
            cellClass: 'text-no-wrap'
          }, {
            text: this.setLabelName('anafResponse'),
            value: 'anaf_ap_iresponse'
          }
        ];
        await this.getItems();
      }
    }
  },
  methods: {
    pageCounter(val: number): void {
      this.pageCount = val;
    },
    setLabelName(name: string): string {
      return intl.t(`views.invoices.logs.${name}Label`);
    },
    async getItems(): Promise<void> {
      this.loading = true;
      this.items = [];
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}/${this.id}/logs`);
        if (!empty(response.data.data)) {
          this.items = response.data.data;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.loading = false;
    },
    close(): void {
      this.modal = false;
      this.$emit('input', false);
    }
  }
});
