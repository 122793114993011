
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import intl from '@/utils/intl';

interface ConfirmDialogOptions {
  width?: number;
  zIndex?: number;
  noConfirm?: boolean;
  confirmBtn?: string;
  cancelBtn?: string;
}

export default Vue.extend({
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null as any,
      reject: null as any,
      message: '',
      title: '',
      options: {
        width: 400,
        zIndex: 200,
        noConfirm: false,
        confirmBtn: intl.t('ok'),
        cancelBtn: intl.t('cancel')
      } as ConfirmDialogOptions
    }
  },
  methods: {
    /** @public **/
    open(title: string, message: string, options?: ConfirmDialogOptions): Promise<boolean> {
      this.dialog = true;
      this.title = title;
      this.message = message;
      if (options) {
        this.options = Object.assign(this.options, options);
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
});
