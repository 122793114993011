
import Vue from 'vue';
import { Header } from '@/types/DataTable';
import { empty } from '@/utils/helpers';
import Http, { HttpError } from '@/utils/Http';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'InvoiceFiles',
  props: {
    status: Boolean,
    type: String,
    invoiceId: Number,
    invoiceNumber: String,
    canDownload: Boolean
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      headers: [] as Array<Header>,
      items: [],
      downloadLoading: 0
    }
  },
  computed: {
    downloadButton(): string {
      return intl.t('buttons.download');
    },
    downloadAllButton(): string {
      return intl.t('buttons.downloadAll');
    }
  },
  watch: {
    status: {
      immediate: true,
      async handler(value: boolean): Promise<void> {
        this.headers = [];
        if (value) {
          this.headers = [
            {
              text: this.setLabelName('name'),
              value: 'file_name'
            },
            {
              text: this.setLabelName('type'),
              value: 'file_type',
              hidden: true
            },
            {
              text: this.setLabelName('description'),
              value: 'file_description',
              hidden: true
            },
            {
              text: '',
              value: 'actions',
              align: 'end'
            }
          ];
          await this.getFiles();
        }
      }
    }
  },
  methods: {
    pageCounter(val: number): void {
      this.pageCount = val;
    },
    setLabelName(name: string): string {
      return intl.t(`views.files.${name}Label`);
    },
    async getFiles(): Promise<void> {
      this.loading = true;
      this.items = [];
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}/${this.invoiceId}/files`);
        if (!empty(response.data.data)) {
          this.items = response.data.data;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.loading = false;
    },
    async downloadFile(file: Record<string, string>): Promise<void> {
      this.downloadLoading = Number(file.id);
      try {
        const response = await (new Http()).post(`/v1/invoices/${this.type}/${this.invoiceId}/files/${file.id}`, null, {
          responseType: 'blob'
        });
        if (response.data.size) {
          let blob = new Blob([response.data], { type: response.data.type });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = file.file_name;
          link.click();
        }
      } catch (error) {
        const err = (error as HttpError).response?.data;
        const blob = new Blob([err], { type: err.type });
        const responseError = await blob.text();
        const errorResponse = JSON.parse(responseError);
        this.$root.$children[0].$children[0].setFlash('error', errorResponse.error.message);
      }
      this.downloadLoading = 0;
    },
    async downloadAll(): Promise<void> {
      this.downloadLoading = -1;
      try {
        const response = await (new Http()).post(`/v1/invoices/${this.type}/${this.invoiceId}/download`, null, {
          responseType: 'blob'
        });
        if (response.data.size) {
          let blob = new Blob([response.data], { type: response.data.type });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.invoiceId + '.zip';
          link.click();
        }
      } catch (error) {
        const err = (error as HttpError).response?.data;
        const blob = new Blob([err], { type: err.type });
        const responseError = await blob.text();
        const errorResponse = JSON.parse(responseError);
        this.$root.$children[0].$children[0].setFlash('error', errorResponse.error.message);
      }
      this.downloadLoading = 0;
    }
  }
});
