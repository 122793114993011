
import Vue from 'vue';
import { FilterData, DateBetween } from '@/types/Filters';
import { each, empty } from '@/utils/helpers';
import intl from '@/utils/intl';

type FilterItemValue = string | number | boolean | DateBetween | null;
type FilterValue = Record<string, FilterItemValue>;
interface LocalFilterData extends FilterData {
  _name?: boolean;
  _name_begin?: boolean;
  _name_end?: boolean;
}

// Button state colors
const buttonStateColor = {
  on: 'orange',
  off: 'primary'
};

export default Vue.extend({
  name: 'Filters',
  props: {
    maxWidth: {
      type: [Number, String],
      default: 'auto'
    },
    minWidth: [Number, String],
    color: {
      type: String,
      default: 'primary'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      buttonColor: buttonStateColor.off,
      localItems: [] as Array<LocalFilterData>,
      filtersMenu: false,
      filtersValues: {}
    };
  },
  computed: {
    firstDayOfWeek(): number {
      return intl.getFirstDayOfWeek();
    },
    dateSuffixBegin(): string {
      return intl.t('filters.dateSuffixBegin');
    },
    dateSuffixEnd(): string {
      return intl.t('filters.dateSuffixEnd');
    },
    filtersButton(): string {
      return intl.t('buttons.filters');
    },
    submitFiltersButton(): string {
      return intl.t('filters.apply');
    },
    resetFiltersButton(): string {
      return intl.t('filters.reset');
    }
  },
  watch: {
    filtersMenu(status: boolean): void {
      this.$emit('input', status);
    },
    items: {
      handler(value: Array<FilterData>): void {
        this.localItems = [];
        const localItems: Array<LocalFilterData> = [];
        if (!empty(value)) {
          each(value, (item: FilterData) => {
            const localItem: LocalFilterData = item as LocalFilterData;
            if (item.type === 'date') {
              localItem._name = false;
            }
            if (item.type === 'date_between') {
              localItem._name_begin = false;
              localItem._name_end = false;
            }
            localItems.push(localItem);
          });
          this.localItems = localItems;
          this.initItems();
        }
      },
      immediate: true
    }
  },
  methods: {
    formatDate(val: FilterItemValue): string {
      return val ? intl.formatDate(String(val)) : '';
    },
    makeAttributeValue(value: unknown, defaultValue = null): unknown {
      return value === undefined ? defaultValue : value;
    },
    makePlaceholder(label: string, placeholder = '', suffix = ''): string {
      let result = placeholder ? placeholder : label;
      if (suffix) {
        result += ' ' + suffix;
      }
      return result;
    },
    calculateColWidth(nr: number): number {
      let result: number;
      switch (nr) {
        case 1:
          result = 6;
          break;
        case 2:
          result = 4;
          break;
        default:
          result = 3;
          break;
      }
      return result;
    },
    calculateBtnWidth(nr: number): number {
      let result: number;
      switch (nr) {
        case 1:
          result = 6;
          break;
        case 2:
          result = 4;
          break;
        default:
          result = 12 - ((nr % 4) * 3);
          break;
      }
      return result;
    },
    setItemValue(idx: number, key: string, value: unknown): void {
      const item = Object.assign({}, this.localItems[idx], { [key]: value });
      this.$nextTick(() => {
        this.localItems.splice(idx, 1, item);
      });
    },
    initItems(): void {
      const filters: FilterValue = {};
      each(this.items, (item: FilterData) => {
        if (item.type == 'date_between') {
          filters[item.name] = {
            begin: null,
            end: null
          };
        } else {
          filters[item.name] = null;
        }
      });
      this.filtersValues = filters;
      this.buttonColor = buttonStateColor.off;
    },
    submit(): void {
      let filters: FilterValue = {};
      each(this.filtersValues, (val: FilterItemValue, key: string) => {
        if (val !== null && val !== '') {
          if (typeof val === 'object' && 'begin' in val && 'end' in val) {
            if (val.begin || val.end) {
              filters[key] = val;
            }
          } else {
            filters[key] = val;
          }
        }
      });
      this.buttonColor = empty(filters) ? buttonStateColor.off : buttonStateColor.on;
      this.filtersMenu = false;
      this.$emit('onSubmitFilters', filters);
    },
    reset(): void {
      this.filtersMenu = false;
      this.initItems();
      this.$emit('onResetFilters', {});
    }
  }
});
